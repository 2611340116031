// Component for updating fixtures on admin panel

function UpdateFixturesAdmin() {
  return (
    <div>
      <p>Update fixtures</p>
    </div>
  );
}

export default UpdateFixturesAdmin;
